<template>
    <component :is="SkinComponent" v-bind="{ ...$attrs, code, notices, summary, boards, page, limit, hideWriteButton, writeScope }" @click="click">
        <template #write-button>
            <v-btn v-if="writable" x-large color="primary" @click="write">글쓰기</v-btn>
        </template>
        <template #pagination>
            <pagination-component :value="page" :count="Math.ceil(summary.totalCount / limit)" @input="(value) => onPage(value)"></pagination-component>
        </template>
    </component>
</template>

<script>
import api from "@/api";

import PaginationComponent from "@/components/client/pagination-component.vue";

export default {
    components: {
        PaginationComponent,
    },
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        category: { type: String, default: undefined },
        _lecture: { type: String, default: undefined },

        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: 10 },

        writeScope: { type: Array, default: null },
        hideWriteButton: { type: Boolean, default: false },

        to: String,
    },
    data() {
        return {
            headers: {
                page: +this.$route.query.page || +this.$props.page || 1,
                skip: this.$props.skip || ((+this.$route.query.page || +this.$props.page || 1) - 1) * this.$props.limit,
                limit: this.$props.limit,
            },

            filter: {
                code: this.$props.code,
                category: this.$props.category,
                "meta._lecture": this.$props._lecture,
            },

            summary: { totalCount: 0 },
            boards: [],
            notices: [],
            categories: [
                { code: "guide", name: "코인거래소 가입", value: "코인거래소 가입" },
                { code: "guide", name: "이더리움 구매", value: "이더리움 구매" },
                { code: "guide", name: "메타마스크 가입", value: "메타마스크 가입" },
                { code: "guide", name: "오픈씨 계정 가입", value: "오픈씨 계정 가입" },
                { code: "guide", name: "작품구입 및 판매활동", value: "작품구입 및 판매활동" },
            ],
        };
    },
    computed: {
        scope() {
            return this.$store.state.payload?.scope || [];
        },
        writable() {
            return this.writeScope ? (this.writeScope || []).some((scope) => this.scope.includes(scope)) : true;
        },
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/list.vue`);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.query.page"() {
            this.onPage(+this.$route.query.page || 1, false);
        },
        category() {
            this.onCategory(this.category);
        },
    },
    methods: {
        init() {
            this.getNotices();
            this.search(false);
        },

        async getNotices() {
            try {
                var { boards: notices } = await api.v1.boards.gets({
                    params: { ...this.filter, isNotice: true },
                });

                this.notices = notices;
            } catch (error) {
                this.handleError(error);
            }
        },

        async search(routable = true, loadmore = false) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, { page: this.headers.page }),
                    });
                }

                var { summary, boards } = await api.v1.boards.gets({
                    headers: this.headers,
                    params: this.filter,
                });

                this.summary = summary;

                this.boards = loadmore ? [...this.boards, ...boards] : boards;
            } catch (error) {
                this.handleError(error);
            }
        },

        write() {
            this.$router.push(`${this.to || this.$route.path}/create`);
        },

        click(board) {
            this.$router.push(`${this.to || this.$route.path}/${board._id}`);
        },

        onLoadmore() {
            this.headers.skip = this.boards.length;
            this.search(true, true);
        },

        onPage(page, routable = true) {
            this.headers.page = page;
            this.headers.skip = (page - 1) * this.limit;
            this.search(routable);
        },

        onCategory(category, routable = false) {
            this.filter.category = category;
            this.headers.page = 1;
            this.headers.skip = 0;
            this.search(routable);
        },

        handleError(error) {
            console.error(error);
            alert(error.response ? error.response.data.message : error.message);
        },
    },
};
</script>
